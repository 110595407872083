<script setup lang="ts">
  import { useUplatformLocale } from '~/features/locale'
  const locales = [
    {
      name: 'RU',
      value: 'ru'
    },
    {
      name: 'ENG',
      value: 'en'
    }
  ] as const

  const { setLocale } = useUplatformLocale()
</script>

<template>
  <div class="language-switcher">
    <div
      v-for="item in locales"
      class="language-switcher__item"
      @click="setLocale(item.value)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<style scoped lang="scss">
  .language-switcher {
    @include flex-container(row, flex-start, center);
    @include font-montserrat-400(12px, 12px, $white-1);
    gap: 6px;
    &__item {
      cursor: pointer;
    }
  }
</style>
